var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:['section',{'section--color-primary' : _vm.color === 'primary'},
															{'section--color-secondary' : _vm.color === 'secondary'},
															{'section--color-tertiary' : _vm.color === 'tertiary'},
															{'section--color-light' : _vm.color === 'light'},
															{'section--color-dark' : _vm.color === 'dark'},
															{'section--size-xlarge' : _vm.size === 'xlarge'},
															{'section--size-large' : _vm.size === 'large'},
															{'section--size-small' : _vm.size === 'small'},
															{'section--size-xsmall' : _vm.size === 'xsmall'},
															{'section--full-width' : _vm.full} ]},[_c('div',{staticClass:"section__container"},[_vm._t("default")],2),(_vm.image)?_c('div',{staticClass:"section__image-background"},[_c('Images',{attrs:{"width":"100%","height":"100%","image":_vm.image}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }