<template>
  <section :class="['section',{'section--color-primary' : color === 'primary'},
															{'section--color-secondary' : color === 'secondary'},
															{'section--color-tertiary' : color === 'tertiary'},
															{'section--color-light' : color === 'light'},
															{'section--color-dark' : color === 'dark'},
															{'section--size-xlarge' : size === 'xlarge'},
															{'section--size-large' : size === 'large'},
															{'section--size-small' : size === 'small'},
															{'section--size-xsmall' : size === 'xsmall'},
															{'section--full-width' : full},]">
		<div class="section__container">
			<slot></slot>
		</div>
		<div class="section__image-background" v-if="image">
			<Images width="100%" height="100%" :image="image" />
		</div>
	</section>
</template>

<script>
export default {
  props: {
    color: String,
		size: String,
		full: Boolean,
		image: String,
  },
	methods: {
	},
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';

.section{
	width: var(--section-mobile-width);
	padding: var(--section-mobile-padding);
	position: relative;
	overflow: hidden;
	@include breakpoint(sm){
		padding: var(--section-padding);
		width: var(--section-width);
	}
}
.section--size-xsmall{
	padding: var(--section-mobile-padding);
	width: var(--section-mobile-width);
	@include breakpoint(sm){
		padding: var(--section-xsmall-padding);
		width: var(--section-xsmall-width);
	}
}
.section--size-small{
	padding: var(--section-mobile-padding);
	width: var(--section-mobile-width);
	@include breakpoint(sm){
		padding: var(--section-small-padding);
		width: var(--section-small-width);
	}
}
.section--size-large{
	padding: var(--section-mobile-padding);
	width: var(--section-mobile-width);
	@include breakpoint(sm){
		padding: var(--section-large-padding);
		width: var(--section-large-width);
	}
}
.section--size-xlarge{
	padding: var(--section-mobile-padding);
	width: var(--section-mobile-width);
	@include breakpoint(sm){
		padding: var(--section-xlarge-padding);
		width: var(--section-xlarge-width);
	}
}
.section__container{
	max-width: var(--section-container-width);
	margin: auto;
	z-index: 2;
	position: relative;
}
.section--full-width{
	.section__container{
		max-width: 100%;
	}
}
.section--color-primary{
	background: var(--section-background-primary);
}
.section--color-secondary{
	background: var(--section-background-secondary);
}
.section--color-tertiary{
	background: var(--section-background-tertiary);
}
.section--color-light{
	background: var(--section-background-light);
}
.section--color-dark{
	background: var(--section-background-dark);
}
.section__image-background{
	position: absolute;
	top: 0;
	left:0;
	z-index: 1;
	min-width: 100%;
}
</style>

<!-- Used in : AR, DS, WM -->